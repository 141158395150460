#observations {
  .search-form {
    .pi-search {
      position: absolute;
      color: $defaultBlue;
      font-size: 1.4em;
      top: calculateRem(7px);
      left: calculateRem(12px);
      pointer-events: none;
    }

    input {
      border: 1px solid $buttonDarkBg;
      border-radius: 0;
    }

    input::placeholder {
      color: $placeHolderColor;
      font-style: italic;
    }

    .search-keyword {
      padding-left: 50px;
    }

    .p-dropdown {
      width: calculateRem(100px);
      min-width: calculateRem(100px);
      border-color: $buttonDarkBg;
      border-radius: 0;
      border-left-width: 0;
      border-right-width: 0;

      .p-dropdown-label {
        color: $textColor;

        &.p-placeholder {
          color: $placeHolderColor;
          font-style: italic;
        }
      }

      .p-dropdown-trigger-icon {
        color: $textColor;
      }
    }

    .p-inputgroup {
      .p-inputgroup-addon {
        padding: 0.25em;
        color: $buttonDarkBg;
        background-color: $commonBg;
        border-color: $buttonDarkBg;
        border-radius: 0;
        border-right-width: 0;
      }

      .pi-filter {
        font-size: 1.5em;
        line-height: 0.5;
        position: relative;
        top: 0.2em;
      }
    }

    .filter-field {
      min-width: 150px;
    }

    .selected-filters {
      min-height: calculateRem(33px);
      margin: 10px 0 0;
      padding: 0;
      list-style-type: none;
      text-align: right;
      width: 100%;

      li {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: calculateRem(12px);
        font-size: 12px;
        background: $buttonLightBg;
        color: $buttonLightTextColor;
        padding: 0 10px;
        border-radius: 20px;

        button {
          color: $buttonLightTextColor;
          border: 0 none;
          background: none;
          padding: 5px 4px;

          &:hover,
          &:active,
          &:focus {
            color: $textColor;
            border: 0 none;
            background: none;
            outline: 0;
          }
        }
      }
    }
  }

  .select-all {
    label {
      font-weight: bold;
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }
  }

  .actions {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    .p-button-icon-right {
      margin-top: calculateRem(5px);
    }

    .switch-container {
      top: calculateRem(2px);

      label {
        cursor: pointer;
      }
    }
  }

  .result-tab {
    overflow: hidden;

    .p-tabview {
      .p-tabview-nav {
        background: none;
        border-bottom-width: 1px;

        li {
          margin-right: 0.25rem;

          a {
            border: thin solid $commonBorder;
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
            background-color: $commonBg;
            padding: 0.5em 0.75em;

            .p-tabview-left-icon {
              font-size: 1.8em;
              color: $textColor;
            }

            &:hover,
            &:focus,
            &:active {
              border-color: $buttonLightBg;
              background-color: $buttonLightTextColor;

              .p-tabview-left-icon {
                color: $buttonLightBg;
              }
            }
          }

          &.p-highlight {
            a {
              border-color: $buttonLightTextColor;
              background-color: $buttonLightBg;
              border-bottom-color: transparent;

              .p-tabview-left-icon {
                color: $buttonLightTextColor;
              }

              &:hover,
              &:focus,
              &:active {
                border-color: $buttonLightTextColor;
                background-color: $buttonLightBg;

                .p-tabview-left-icon {
                  color: $buttonLightTextColor;
                }
              }
            }
          }

          &.compteur {
            position: relative;
            padding-top: calculateRem(8px);

            a {
              display: flex;
              align-items: center;
              height: 100%;
              padding: 0;
              border: 0 none;

              &:hover,
              &:active,
              &:focus {
                background: $commonBg;
              }

              &:focus {
                box-shadow: unset;
                outline: 0;
              }

              .p-progress-spinner {
                width: 25px;
                height: 25px;
                display: inline-block;
                margin-right: 10px;
              }

              span {
                display: inline-block;
              }
            }
          }
        }
      }

      .p-tabview-panels {
        height: 100%;
        padding: 0;
        overflow: hidden;

        .p-tabview-panel {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .map-container {
    .map-tooltip {
      background: $mapTooltipBg;
      color: $textInverseColor;
      padding: 5px 10px;
      font-size: 0.9em;
      border-radius: 4px;
      box-shadow: 0px 0px 5px $mapTooltipShadow;

      &:before {
        content: " ";
        border: 8px transparent solid;
        border-top-color: $mapTooltipBg;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-top: -1px;
        margin-left: -8px;
        width: 0;
        height: 0;
      }
    }

    .map-tooltip-id {
      font-size: 0.9em;
    }
  }

  .table-container {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    .header{
      display: flex;
      gap: 10px;
      .action-bouton{
        align-self: center;
      }
      .selected-results-counter{
        align-self: center;
      }
    }
  }

  .table-results {
    width: 100%;

    td {
      border-bottom: thin solid $commonBorder;
      padding: 10px 5px;
      vertical-align: middle;
      font-size: 0.9em;
    }

    tr:last-child {
      td {
        border-bottom: 0 none;
      }
    }

    .obs-image {
      border-radius: 20px;
      height: 40px;
      width: 40px;
      overflow: hidden;
      background: $listImgBg;
      color: $listImgColor;
      font-size: 1.3em;
    }

    .obs-title {
      padding-left: 25px;

      span {
        display: block;
      }

      .title {
        display: inline-block;
        position: relative;
        font-weight: bold;

        &:before {
          content: " ";
          position: absolute;
          left: -16px;
          top: 0.3em;
          width: 0.65em;
          height: 0.65em;
          border-radius: 100%;
          background: $dotPendingColor;
        }
      }

      &.valid {
        .title {
          font-weight: normal;

          &:before {
            background-color: $dotValidColor;
          }
        }
      }

      .author {
        color: $listAuthorColor;
      }
    }

    .compteurs {
      padding: 5px;

      .compteur {
        border-right: thin solid $commonBorder;
        padding-right: 10px;
      }

      span {
        display: block;
        color: $textListSubColor;
      }
    }
  }
}
