@import "./variables";
@import "./mixins";
@import "./globals";

@import "../app/core/layout/header/header.component";
@import "../app/core/layout/compass/compass.component";
@import "../app/core/layout/loader/loader.component";

@import "../app/core/forms/import-observations/import-observations-form";
@import "../app/core/forms/document-list/document-list.component";
@import "../app/core/forms/observation/observation-form.component";
@import "../app/core/forms/observation/observation-map/observation-map.component";
@import "../app/core/forms/lithological-description/lithological-description-form.component";
@import "../app/core/forms/excel-import/excel-import.component";

@import "../app/core/lists/observations/observations.component";
@import "../app/core/lists/analysis/analysis.component";
@import "../app/core/lists/samples/samples.component";

@import "../app/core/views/observation-detail-panel/observation-detail-panel.component";
@import "../app/core/views/layer-switcher-panel/layer-switcher-panel.component";
@import "primeicons/primeicons.css";
