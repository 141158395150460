// Tags
html,
body,
app-root {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  background: $formBg;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

h4,
h5,
h6 {
  font-size: 1rem;
}

hr {
  border-color: $borderGray;
}

body {

  [class*="-button"],
  [class*="-button"]:enabled {
    @include buttonType("light", $buttonLightTextColor, $buttonLightBg);
    @include buttonType("dark", $buttonDarkTextColor, $buttonDarkBg);
    @include buttonType("white", $buttonWhiteTextColor, $buttonWhiteBg);
    @include buttonType("red", $buttonRedTextColor, $buttonRedBg);

    &.dark-button {
      border-color: $buttonDarkBg;
    }

    &.red-button {
      border-color: $buttonRedTextColor;

      &:hover,
      &:focus,
      &:active {
        border-color: $buttonRedTextColor;
      }
    }
  }
}

small {
  font-size: 80%;
  font-weight: 400;
}

.link-style {
  display: inline-block;
  padding: 0;
  margin: 0;
  background: none;
  border: 0 none;
  color: $textColor;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}

a[class*="-button"] {

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

fieldset {
  border: thin solid $borderGray;
  border-radius: $borderRadius;
  position: relative;
  padding: 1rem;

  legend {
    background: $commonBg;
    font-size: 1.1rem;
    padding: 0 0.5rem;
    position: absolute;
    top: -0.8em;
    left: 0.5rem;
    width: auto;
  }
}

// Libraries overrides

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: none;
}

@keyframes ui-progress-spinner-color {

  100%,
  0% {
    stroke: $defaultBlue;
  }

  40% {
    stroke: $defaultBlue;
  }

  66% {
    stroke: $defaultBlue;
  }

  80%,
  90% {
    stroke: $defaultBlue;
  }
}

body {
  .p-inputtext {
    font-size: calculateRem(14px);
    padding: 0.429rem;
  }

  .p-autocomplete {
    width: 100%;

    .p-autocomplete-input {
      width: 100%;
    }

    .p-autocomplete-dropdown {
      background: transparent;
      border-color: $inputBorderColor;
      color: $textColor;

      &:hover,
      &:focus,
      &:active {
        border-color: $buttonWhiteTextColor;
        background-color: $buttonWhiteTextColor;
        color: $buttonWhiteBg;
      }

      &:disabled {
        cursor: default;

        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
          border-color: $inputBorderColor;
          color: $textColor;
        }
      }
    }

    &.p-autocomplete-multiple {
      .p-autocomplete-multiple-container {
        width: 100%;

        .p-autocomplete-token {
          font-size: 0.9em;
          color: $textInverseColor;
          background-color: $defaultBlue;
        }
      }
    }
  }

  .p-autocomplete-panel {
    .p-autocomplete-items {
      .p-autocomplete-item {
        white-space: normal;
      }
    }
  }

  .p-inputtext[type="number"] {
    appearance: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .p-inputgroup-addon {
    background: $commonBg;
    color: $defaultBlue;
    border-color: $defaultBlue;
  }

  .p-button {
    font-size: calculateRem(14px);

    .pi {
      font-size: 1em;
    }

    .p-button-icon-right {
      float: right;
    }

    &:enabled:focus {
      box-shadow: none;
    }
  }

  a.p-button {
    text-decoration: none;
  }

  .p-paginator {
    border: 0 none;
    display: flex;
    text-align: left;

    .p-paginator-first {
      margin-left: auto;
    }
  }

  .import-modal {
    width: 700px;

    .p-dialog-content {
      padding: 0;
    }
  }

  .edit-modal {
    max-width: 700px;
    width: 80%;

    .p-dialog-content {
      padding: 0;
      height: 75vh;
    }
  }

  .p-dialog {
    .p-dialog-header {
      border-bottom: thin solid $commonBorder;
      padding: 0.5rem 1rem;
      cursor: move;
    }

    &.p-confirm-dialog {
      .p-dialog-content {
        max-height: 70vh;
        padding: 1rem;
        overflow: auto;
      }

      .p-confirm-dialog-message {
        margin-left: 0;
      }
    }

    .p-dialog-footer {
      border-top: thin solid $commonBorder;
      padding: 0.5rem 1rem;
    }
  }

  .image-modal {
    max-width: 1000px;
    top: 10% !important;
    width: 90%;

    .p-dialog-content {
      padding: 0;
      text-align: center;
    }

    img {
      max-width: 100%;
      max-height: 75vh;
    }
  }

  .p-menu {
    width: auto;

    .p-menuitem-link {
      white-space: nowrap;
    }

    .danger-menu-item {
      .p-menuitem-link {

        .p-menuitem-icon,
        .p-menuitem-text {
          color: $errorColor;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: $buttonRedTextColor;

          .p-menuitem-icon,
          .p-menuitem-text {
            color: $buttonRedBg;
          }
        }
      }
    }
  }

  .p-calendar {
    width: 100%;

    .p-inputtext {
      width: 100%;
    }
  }

  .p-checkbox {
    .p-checkbox-box {
      &.p-state-active {
        border-color: $defaultBlue;
        background-color: $defaultBlue;
      }
    }
  }

  .p-fileupload {
    .p-fileupload-buttonbar {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
    }
  }

  .p-toast {
    z-index: 9999 !important;

    .p-toast-message-text-content {
      white-space: pre-wrap;
    }
  }

  .p-inputswitch {
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background-color: $defaultBlue;
      }
    }
  }

  .p-checkbox-label {
    cursor: pointer;
  }
}

.p-helper-hidden-accessible {
  top: 0;
  left: 0;
}

.ol-attribution {
  font-size: 0.9em;
  max-width: 60%;
}

.ol-mouse-position {
  background: $mapControlBg;
  border-bottom-left-radius: 4px;
  font-size: 0.9em;
  top: 0;
  right: 0;
  padding: 2px 5px;
}

.ol-background-selector {
  z-index: 1;
  top: 8px;
  left: 40px;
  padding: 3px;

  .ol-background-selector-inner {
    background: $mapControlBtnBg;
    border-radius: 3px;
    padding: 1px 1px 1px 5px;
  }

  label {
    color: $mapControlBtnColor;
    padding-right: 5px;
    font-size: 0.9em;
    margin: 0;
  }

  .p-dropdown-label {
    padding: 3px 5px;
    color: $textColor;
  }
}

// Custom global classes
.notif {
  margin: 0 !important;

  &.error {
    color: $errorColor;
    background: $errorBgColor;
  }
  &.alert {
    color: $alertColor;
    background: $alertBgColor;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.more-info-accordion {
  p-accordiontab {
    .p-accordion-header {
      .p-accordion-header-link {
        .p-accordion-toggle-icon {
          margin-right: 0;
        }
      }
    }

    .p-accordion-header:not(.p-disabled) {
      .p-accordion-header-link {
        border: 0 none;
        background: none;
        padding: 0;
        display: inline-block;
        font-weight: normal;

        &:focus {
          box-shadow: none;
        }

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      &:not(.p-highlight),
      &.p-highlight {
        &:hover {
          .p-accordion-header-link {
            background: none;
          }
        }
      }

      &.p-highlight {
        .p-accordion-header-link {
          background: none;
        }
      }
    }

    .p-accordion-content {
      padding: 0;
      border: 0;
      margin: 0;
    }

  }

  >.p-accordion {
    >p-accordiontab {
      >.p-accordion-tab {
        >.p-toggleable-content {
          >.p-accordion-content {
            margin-top: 10px;
          }
        }
      }
    }
  }

  &.with-sub-list {
    >.p-accordion {
      >p-accordiontab {
        >.p-accordion-tab {
          padding-bottom: 1.5rem;
          transition: padding .4s cubic-bezier(0.86, 0, 0.07, 1);

          &.p-accordion-tab-active {
            padding-bottom: 0;
          }

          >.p-accordion-header {
            >.p-accordion-header-link {
              position: relative;
              z-index: 1;
            }
          }

          >.p-toggleable-content {
            margin-top: -1.5rem;
          }
        }
      }
    }
  }
}

.break-words {
  word-break: break-all;
}

.nl-displayed {
  white-space: pre-line;
}

.no-break {
  white-space: nowrap;
}

.required {
  font-weight: bolder;
  &:after {
    content: " *";
    color: $errorColor;
  }
}

.geofield-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  width: 20px;
  height: 100%;
}

.geofield-icon-file {
  background-image: url("../assets/images/geofield-icons/file.png");
}

.geofield-icon-picture {
  background-image: url("../assets/images/geofield-icons/picture.png");
}

.cursor-pointer {
  cursor: pointer;
}

.smallDialog {
  .p-dialog-titlebar {
    padding: 5px !important;
  }
}
