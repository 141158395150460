#observation-detail-panel {
  p {
    margin-bottom: 1rem;
  }
  .first-panel,
  .second-panel {
    background-color: $commonBg;
    box-shadow: 3px 0 5px $commonShadow;
    position: absolute;
    top: 0;
    height: 100%;
  }
  .first-panel {
    left: 0;
    width: 350px;
    z-index: 5;
  }
  .second-panel {
    left: 350px;
    width: 300px;
    z-index: 3;
  }
  .panel-title {
    font-size: 1rem;
    border-bottom: 1px solid $commonBorder;
    padding: 10px 15px;
    margin: 0;
  }
  .panel-content {
    height: 100%;
    overflow: auto;
    padding: 10px 15px;
  }
  .panel-footer {
    border-top: 1px solid $commonBorder;
    padding: 10px 15px;
  }
  .bloc-title {
    color: $defaultBlue;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 15px;
  }
  .items-list {
    padding-left: 20px;
  }
  .item-details-list {
    padding: 0;
    list-style-type: none;
    margin: 5px 0 10px;
    li {
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px;
      button,
      span {
        background: $buttonLightBg;
        border: 0 none;
        border-radius: 4px;
        color: $textColor;
        display: block;
        font-size: 0.9em;
        padding: 4px 10px;
      }
      button {
        transition: background-color 0.2s ease;
        &:hover,
        &:active,
        &:focus {
          background: $buttonDarkBg;
          color: $buttonDarkTextColor;
        }
      }
      &.selected {
        button {
          background: $buttonDarkBg;
          color: $buttonDarkTextColor;
        }
      }
    }
  }
  .details {
    dt {
      color: $defaultBlue;
      font-weight: normal;
      margin-bottom: 0.5rem;
    }
    .grid {
      dt,
      dd {
        margin-bottom: 0;
      }
    }
  }
  .line-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    li {
      border-bottom: 1px solid $commonBorder;
      margin-bottom: 10px;
      padding-bottom: 10px;
      &:last-child {
        border-bottom: 0 none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      dl {
        margin-bottom: 0;
      }
      dd {
        margin-bottom: 3px;
      }
    }
  }
}
