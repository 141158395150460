.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $loaderBg;
  z-index: 9999;
  .lds-ring {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -70px;
    margin-top: -70px;
    width: 140px;
    height: 140px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 134px;
    height: 134px;
    border: 12px solid $loaderColor;
    border-radius: 50%;
    animation: lds-ring 1.6s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $loaderColor transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.6s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.4s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.2s;
  }
}

#main-loader {
  position: fixed;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
