// CSS spécifique au header
#header {
  background: $commonBg;
  box-shadow: 0 1px 5px $commonShadow;
  position: relative;
  z-index: 2;
  .navbar-brand {
    color: $buttonLightTextColor;
    font-weight: bold;
    border-right: thin solid $commonBorder;
  }
  .logout {
    background-color: #707579;
    border-width: 0;
  }
  .p-menu {
    width: auto;
  }
  .p-button-icon-right {
    margin-top: calculateRem(5px);
  }
  .geolsuite-menu-container {
    .menu-button {
      box-shadow: none;
      padding: 5px;
      img {
        position: relative;
        top: -3px;
      }
    }
  }
}
