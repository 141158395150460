.import-export {
  max-height: 75vh;
  label {
    display: block;
    margin-bottom: 5px;
  }
  .import-result {
    li {
      ul {
        padding-left: 20px;
        list-style-type: initial;
      }
    }
  }
}
