#samples {
  .analysis-container {
    background: $analysisBg;

    .p-accordion {
      p-accordiontab {
        .p-accordion-content {
          background: transparent;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }

  }

  .p-tabview {

    .p-tabview-nav,
    .p-tabview-panels {
      background: none;
    }

    .p-tabview-nav {
      li {
        .p-tabview-nav-link {
          background: none;
        }

        &.p-highlight {
          .p-tabview-nav-link {
            background: none;
          }
        }
      }
    }
  }
}