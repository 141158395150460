#observation-form {
  .observation-map {
    .ol-background-selector {
      label {
        margin-bottom: 0;
      }
      .background-selector-label {
        display: inline-block;
      }
    }
  }
}

.observation-map {
  .ol-destination-selector {
    top: 45px;
    width: auto;
  }
  .ol-background-selector {
    label {
      margin-bottom: 0;
    }
    .background-selector-label {
      display: inline-block;
    }
  }
  .ol-background-selector-inner {
    padding: 1px;
  }
  .p-autocomplete {
    .p-autocomplete-input {
      padding: 3px 5px;
    }
  }
}

.destination-autocomplete-panel {
  max-width: 300px;
}
