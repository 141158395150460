$commonBg: white;
$formBg: #fafbfd;
$errorBgColor: #fff0f0;
$alertBgColor: #fff8c7;
$listImgBg: #fafbfd;
$buttonLightBg: #e5f0fd;
$buttonDarkBg: #2598fe;
$buttonWhiteBg: #fff;
$buttonRedBg: #fbe5e6;
$buttonDisabledBg: #dee0e2;
$mapPanelSubitemBg: #e5f0fd;
$mapControlBtnBg: rgba(0, 60, 136, 0.5);
$mapControlBg: rgba(255, 255, 255, 0.8);
$loaderBg: rgba(0, 0, 0, 0.6);
$mapTooltipBg: #333;
$analysisBg: #f3f3f3;

$commonBorder: #e6e6e6;
$inputBorderColor: #ced4da;

$commonShadow: rgba(0, 0, 0, 0.4);
$formItemBtnShadow: rgba(0, 0, 0, 0.2);
$mapTooltipShadow: rgba(255, 255, 255, 0.5);

$defaultBlue: #309dfe;

$textColor: #2e3244;
$textInverseColor: white;
$errorColor: #d92121;
$alertColor: #a9922f;
$textListSubColor: #7b7f83;
$textGrayColor: #bdbfc5;
$placeHolderColor: rgb(137, 138, 143);
$inputLabelColor: #a0a4a7;
$buttonLightTextColor: $defaultBlue;
$buttonDarkTextColor: $textInverseColor;
$buttonWhiteTextColor: $defaultBlue;
$buttonRedTextColor: #ff1a1a;
$buttonDisabledTextColor: $textInverseColor;
$listImgColor: #b5b8bb;
$listAuthorColor: #b0d9fe;
$mapControlBtnColor: $textInverseColor;
$loaderColor: white;

$dotPendingColor: #484c5b;
$dotValidColor: #14c114;

$borderRadius: 5px;
$borderGray: #bdbfc5;
$headerSize: 60px;
