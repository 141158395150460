// CSS spécifique au formulaire d'ajout/edition d'une observation
#observation-form {
  .left-panel {
    min-width: 258px;
    .p-listbox {
      width: auto;
    }
    .p-listbox {
      background: unset;
      border: unset;
      display: flex;
      flex-direction: column;
    }
    .p-listbox-list-wrapper {
      position: relative;
      &:before {
        content: " ";
        border-left: 2px solid $borderGray;
        height: 100%;
        position: absolute;
        top: 0;
        left: calculateRem(11px);
      }
    }
    .p-listbox-list {
      padding: 0;
      position: relative;
      background: unset;
      z-index: 1;
    }
    .p-listbox-item {
      font-size: 0.9em;
      margin-bottom: calculateRem(15px);
      padding: 0;
      user-select: none;
      &:focus {
        outline: 0;
      }
      .p-helper-clearfix {
        white-space: nowrap;
      }
      .step-number {
        position: relative;
        border: 1px solid $borderGray;
        background-color: white;
        width: calculateRem(24px);
        height: calculateRem(24px);
        &:before {
          content: " ";
          border-radius: 50%;
          width: calculateRem(16px);
          height: calculateRem(16px);
          position: absolute;
          top: calculateRem(3px);
          left: calculateRem(3px);
          background: $defaultBlue;
          transform: scale(0);
          opacity: 0;
          transition: transform 0.3s ease, opacity 0.3s ease;
        }
      }
      &:hover:not(.p-highlight) {
        background: unset;
        color: unset;
        .step-number {
          background-color: $borderGray;
        }
      }
      &:focus {
        box-shadow: none;
      }
      &:not(.p-highlight) {
        background: unset;
        color: $textGrayColor;
      }
      &.p-highlight {
        background: unset;
        color: unset;
        .step-number {
          &:before {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .p-listbox-footer {
      margin-top: auto;
      font-size: calculateRem(12px);
      background-color: transparent;
      border: unset;
      .mandatory {
        span {
          color: $errorColor;
        }
      }
      dt,
      dd {
        display: inline-block;
      }
      dt {
        margin-right: 0.2rem;
      }
      dd {
        margin-bottom: 0;
      }
    }
  }
  .right-panel {
    .test{
      .p-progress-spinner-circle {
        stroke: $defaultBlue !important;
      }
    }
    .alert {
      padding: 5px 10px;
    }
    .step {
      h2 {
        margin-bottom: 0;
      }
      label {
        display: block;
        margin-bottom: 5px;
      }
      .p-checkbox-label {
        display: inline-block;
        margin-bottom: 0;
      }
      .content {
        background-color: $commonBg;
        border: 1px solid $borderGray;
      }
    }
    .list-item {
      position: relative;
      margin-bottom: 1.5rem;
      .list-item-tools {
        position: absolute;
        bottom: -1rem;
        right: 0.5rem;
        button {
          box-shadow: 0 2px 5px $formItemBtnShadow;
        }
      }
      dl {
        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          li {
            display: inline-block;
            margin-right: 0.5rem;
          }
        }
      }
      dt {
        color: $defaultBlue;
        font-weight: normal;
        margin-bottom: 0.5rem;
      }
      .sublist-item {
        background: $buttonLightBg;
        border: 0 none;
        border-radius: 4px;
        color: $textColor;
        display: inline-block;
        margin-bottom: 0.5rem;
        font-size: 0.9em;
        padding: 4px 10px;
      }
    }
  }
}
