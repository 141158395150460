#layer-switcher {
  background: $commonBg;
  border: thin solid $inputBorderColor;
  border-right: 0;
  position: absolute;
  top: 50px;
  right: -500px;
  width: 500px;
  z-index: 1;
  transition: right 0.3s ease;
  &.active {
    right: 0;
    box-shadow: 0 0 5px $commonShadow;
  }
  .panel-btn {
    position: absolute;
    top: 0;
    right: 100%;
    padding: 3px 0;
    font-size: 1.2em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    box-shadow: 0 0 5px $commonShadow;
  }
  .layer-switcher-content {
    position: relative;
    background: $commonBg;
    max-height: 360px;
    overflow: auto;
    .p-tabview {
      padding: 10px;
      ul.p-tabview-nav {
        margin-bottom: 0;
        position: relative;
        bottom: -1px;
        li {
          a {
            padding: 0.3em 0.6em;
            .p-tabview-left-icon {
              font-size: 1.5em;
            }
          }
        }
      }
      .p-tabview-panels {
        height: auto;
        overflow: auto;
        .p-tabview-panel {
          height: auto;
          padding: 10px;
          border: thin solid $commonBorder;
        }
      }
    }
    dt {
      color: $textListSubColor;
      padding-bottom: 2px;
      margin-bottom: 5px;
      border-bottom: thin solid $inputBorderColor;
    }
    dd {
      color: $textListSubColor;
      font-size: 0.9em;
    }
    .import-layers-title {
      font-size: calculateRem(14px);
      font-weight: bold;
      color: $textListSubColor;
      padding-bottom: 2px;
      margin-bottom: 5px;
      border-bottom: thin solid $inputBorderColor;
    }
  }
  .layer-switcher-list {
    padding: 0;
    margin: 0 -7px;
    list-style-type: none;
    p-button {
      cursor: default;
    }
    .p-button {
      background: none;
      border: 0 none;
      color: $textColor;
      padding: 0;
      opacity: 0.6;
      transition: opacity 0.3s ease;
      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
      &:disabled {
        visibility: hidden;
        cursor: default;
      }
      .p-button-text {
        padding: 0.3em;
      }
    }
    .delete-button-container {
      .p-button {
        color: $buttonRedTextColor;
      }
    }
  }
}
