.document-list {
  .image-list {
    .image-thumbnail {
      text-align: left;
      .selectFile{
        align-self: center;
      }
      img {
        max-width: 90%;
        cursor: pointer;
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
    }
  }
}
