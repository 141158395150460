@use 'sass:math';

@function calculateRem($size) {
  $remSize: math.div($size, 16px);
  @return #{$remSize}rem;
}

@mixin buttonType($name, $textColor, $bgColor) {
  &.#{$name}-button {
    color: #{$textColor};
    background-color: #{$bgColor};
    border-color: #{$textColor};
    &:hover,
    &:focus,
    &:active {
      color: #{$bgColor};
      background-color: #{$textColor};
      border-color: #{$bgColor};
    }
    &:disabled {
      &:hover,
      &:focus,
      &:active {
        color: #{$textColor};
        background-color: #{$bgColor};
        border-color: #{$textColor};
      }
    }
  }
}
