.compass {
  background-image: url("../../../../assets/images/compass.png");
  background-size: contain;
  position: relative;
  overflow: hidden;
  .rotable {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1s ease;
  }

  .arrow {
    background-image: url("../../../../assets/images/compass-needle.png");
  }

  .azimuth {
    background-image: url("../../../../assets/images/compass-azimuth.png");
  }
}
